
.email a[data-v-99780696] {
  color: #000;
  text-decoration: underline;
}
.email__title[data-v-99780696] {
  font-size: 1.5rem !important;
  font-weight: 800;
  margin-bottom: 2rem;
}
.email__check-email[data-v-99780696] {
  font-size: 16px;
}
.email__support[data-v-99780696] {
  font-size: 16px;
}
.email button[data-v-99780696] {
  font-size: 16px;
  font-weight: 800;
}
.email button[data-v-99780696]:hover {
  background-color: #000;
  color: #fff;
}
