
.status-subscribed {
  font-weight: bold;
  color: green;
}
.feed_img_cover {
  background-color: #eee;
  width: 3em;
  height: 3em;
  border: 1px solid #ccc;
  object-fit: cover;
}
.feed_disabled td {
  background-color: #efefef !important;
  color: grey;
}
