
.login[data-v-efda5fd8] {
  color: #000;
}
.login__logo-wrap[data-v-efda5fd8] {
  margin-bottom: 5rem;
}
.login a[data-v-efda5fd8] {
  color: #000;
  text-decoration: underline;
}
.login__inner[data-v-efda5fd8] {
  height: 100%;
  margin: 0 auto;
  max-width: 460px;
  width: 90%;
}
.login__btn[data-v-efda5fd8] {
  font-size: 16px;
  font-weight: 800;
}
.login__title[data-v-efda5fd8] {
  font-size: 28px !important;
  font-weight: 800 !important;
}
.login__password-field[data-v-efda5fd8] {
  margin-bottom: 1rem;
}
.login__checkboxes[data-v-efda5fd8] {
  margin: 0 .8rem .8rem;
}
.login__checkboxes > div[data-v-efda5fd8] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.login__need-credentials[data-v-efda5fd8] {
  font-size: 14px;
  margin-bottom: .8rem;
}
.login__btn-wrap[data-v-efda5fd8] {
  margin-bottom: 1rem;
}
.login__access-catalog[data-v-efda5fd8] {
  font-size: 16px;
  font-weight: 800;
  text-decoration: none !important;
}
.login__access-catalog i[data-v-efda5fd8] {
  margin-right: 5px;
}
@media (min-width: 992px) {
.login__checkboxes[data-v-efda5fd8] {
    margin: 0 1rem 1rem;
}
.login__btn-col[data-v-efda5fd8] {
    min-width: 150px !important;
    max-width: 150px !important;
}
.login__btn:disabled .login__btn-text[data-v-efda5fd8] {
    margin-left: -21px;
}
}
@media (min-width: 1200px) {
.login__password-field[data-v-efda5fd8] {
    margin-bottom: 2rem;
}
.login__checkboxes[data-v-efda5fd8] {
    margin: 0 1.5rem 1.8rem;
}
.login .form-group-default[data-v-efda5fd8] {
    padding-left: 24px;
    padding-right: 24px;
}
.login__need-credentials[data-v-efda5fd8] {
    margin-bottom: 1.5rem;
}
}
@media (min-width: 1360px) {
.login__inner[data-v-efda5fd8] {
      width: 75%;
}
}
.login button[data-v-efda5fd8]:hover {
  background-color: #000;
  color: #fff;
}
.login .forgot-link[data-v-efda5fd8],
.login .checkboxes span[data-v-efda5fd8]{
  font-size: 14px !important;
  color: #000;
}
.login .checkboxes label[data-v-efda5fd8] {
  display: inline-block;
  padding-right: 10px;
  white-space: nowrap;
}
.login .checkboxes input[data-v-efda5fd8] {
  vertical-align: middle;
}
.login .checkboxes label span[data-v-efda5fd8] {
  vertical-align: middle;
}
