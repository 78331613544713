
.page-link {
  border: 0px;
}
li.page-item.active > a.page-link {
  background-color: transparent !important;
  border-bottom: 2px solid #157ef3;
  color: black !important;
}
.page-link {
  background-color: transparent;
}
li.page-item.disabled > a.page-link {
  background-color: transparent;
}
