
.subcriptions-list[data-v-cdf8e89c] {
  max-height: 400px;
  overflow: auto;
}
button[data-v-cdf8e89c] {
  height: 100%;
}
button.active[data-v-cdf8e89c] {
  background-color: #157ef3;
  border-color: #157ef3;
}
button.active[data-v-cdf8e89c]:hover {
  color: #000;
}
