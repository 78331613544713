
.plans__description[data-v-1a802f34] {
  font-size: 14px !important;
}
.plans .card[data-v-1a802f34] {
  border: 0 !important;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
  padding-bottom: 15px;
}
.plan__award-icon[data-v-1a802f34] {
  color: #157ef3 !important;
  position: absolute;
  right: 0;
  top: 11px;
}
.plans .card-body[data-v-1a802f34] {
  padding: 0 !important;
}
.plans__available-text[data-v-1a802f34] {
  margin: 1rem 0 2rem;
}
.plans__periodicity-btns[data-v-1a802f34] {
  text-align: center;
  margin-bottom: 2rem;
}
.plans .card .card-header[data-v-1a802f34] {
  padding: 12px 20px 7px 20px !important;
}
.plans .purchase-btn[data-v-1a802f34],
.plans .trial-btn[data-v-1a802f34] {
  background-color: #157ef3 !important;
  border: 0 !important;
  color: #fff;
  padding: 10px 0 !important;
  text-align: center;
}
.plans .btn.active[data-v-1a802f34] {
  background-color: #157ef3 !important;
  color: #fff !important;
}
.plans .purchase-btn[data-v-1a802f34]:hover {
  color: #fff!important;
}
.plan-name[data-v-1a802f34] {
  color: #157ef3 !important;
  font-size: 3rem;
  font-weight: 800 !important;
  word-wrap: normal !important;
}
@media (min-width: 992px) {
.plans .plan-name[data-v-1a802f34] {
    font-size: 1.5rem  !important;
}
.plans__plan[data-v-1a802f34] {
    min-height: 250px;
}
.plans__intro[data-v-1a802f34] {
    display: flex;
    justify-content: space-between;
}
}
.plans .text-muted[data-v-1a802f34] {
  color: #5b5b5b !important;
}
.plans .plans__price[data-v-1a802f34] {
  font-size: 1.2rem;
  margin-bottom: .3rem;
}
.plans .plans__price-save[data-v-1a802f34] {
  font-size: .8rem;
}
.plans__feeds[data-v-1a802f34] {
  font-size: 1.1rem !important;
}
.plans__enterprise-plan .plans__description[data-v-1a802f34] {
  margin-top: .5rem;
}
.feeds-view[data-v-1a802f34] {
  min-height: 60vh;
}
table[data-v-1a802f34] {
  border: 1px solid #ccc;
}
.card-header h1[data-v-1a802f34] {
  font-size: 1.6em !important;
  margin-bottom: 0 !important;
}
.card[data-v-1a802f34]{
  border-color: #ccc;
}
.save_annually[data-v-1a802f34]{
  font-size: .6em;
  color: #5b5b5b;
}
.plans .plans__plan.selected-plan[data-v-1a802f34] {
  background-color: #fff !important;
  border: 2px solid #157ef3 !important;
  color: #222 !important;
}
