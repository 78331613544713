
.status-subscribed[data-v-327c34d6] {
  font-weight: bold;
  color: green;
}
.feed_disabled td[data-v-327c34d6] {
  background-color: #efefef !important;
  color: grey;
}
#filter.form-control[data-v-327c34d6] {
  color: black;
  border: 1px black solid;
}
#catalog-main-table[data-v-327c34d6] {
  border-top: 1px solid #ccc;
}
#catalog-filters[data-v-327c34d6] {
  margin-top: 1em;
}
#search-header[data-v-327c34d6] {
  background-color: #157ef3;
  color: white !important;
}
#search-header h1[data-v-327c34d6] {
  color: white;
}
#feed-search[data-v-327c34d6]{
  border:0px;
  -webkit-border-radius:0px;
  color:#000;
}
