
.feed-category[data-v-3386ba94] {
  text-transform: capitalize;
  margin-right: 0.5em;
}
h1.feed-title[data-v-3386ba94] {
  font-size: 2.4em !important;
  line-height: 1em;
}
.feed-cover-image[data-v-3386ba94] {
  width: 5em;
  height: 5em;
  border: 1px solid #ccc;
  margin-right: 0.5em;
  background-color: #eee;
  object-fit: cover;
  /*display: block;*/
}
