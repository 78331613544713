:root {
  --gap: 1rem;
}

@media (min-width: 992px) {
  :root {
    --gap: 2rem;
  }
}

@media (min-width: 1200px) {
  :root {
    --gap: 3rem;
  }
}


.table-pagination {
  height: 2em;
  /*border: 1px solid #eaeaea;
  border-top: 0;*/
}

.table-pagination nav {
  /*margin-right: -1px;
  float: right; */
}

li.page-item {
  padding: 0 !important;
}

.page-item .page-link {
  border-radius: 0 !important;
  border-top: 0;
  border-bottom: 0;
  color: inherit !important;
}

.page-item.active {
  color: white;
}

.page-item.active .page-link {
  background: #157ef3;
  border-color: #157ef3;
}

.page-item.active .page-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 187, 153, 0.25);
}

#app {
  height: 100%;
  width: 100%;
  background-color: black;
}

a,
button,
tr {
  cursor: pointer;
}

fh {
  min-height: 100%;
  height: 100%;
}

fw {
  min-width: 100%;
  width: 100%;
}

.horizontal-app-menu.header.header-md-height {
  height: 65px;
  z-index: 99999999999;
  background: inherit;
  width: 100%;
}

.horizontal-app-menu.fixed-header .page-container {
  overflow: auto;
}

.dash-content {
  padding-top: 65px;
}

.txt-thumb {
  padding: 10px;
  background: white;
  text-transform: uppercase;
}

/* spinner */
.btn-lg,
.btn-group-lg > .btn,
.btn-cons {
  padding-right: 35px !important;
}

.spinner {
  width: 20px;
  height: 20px;
  margin: auto;
  border: 2.5px solid white;
  border-bottom-color: green;
  border-radius: 100%;
  animation: rotate 0.9s linear 0s infinite;
  -webkit-animation: rotate 0.9s linear 0s infinite;
  position: absolute;
}

.centered-spinner {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.action-spinner {
  right: 8px;
}

.modal-m {
  max-width: 500px !important;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media (max-width: 991px) {
  #app {
    overflow: scroll;
    overflow-x: hidden;
  }

  .header.p-r-0.bg-primary {
    position: fixed;
  }

  .horizontal-app-menu.fixed-header .page-container {
    overflow: hidden;
  }
}
@media (min-width: 768px) {
  #app {
    overflow: auto;
    overflow-x: hidden;
  }
}

.cdp__input-error {
  color: #157ef3;
  display: inline-block;
  margin-bottom: .5rem;
}

.underline {
  text-decoration: underline;
}

.no-scroll {
  overflow: hidden !important;
}

.filters-icon {
  margin-right: .3rem;
}

.modal-header {
  position: relative !important;
}

/*
autosuggest component styles
*/

#autosuggest__input {
  outline: none;
  position: relative;
  display: block;
  border: 1px solid #616161;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

#autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  border: 2px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0px;
  overflow: scroll;
  overflow-x: hidden;
  max-height: 200px;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.autosuggest__results .autosuggest__results_item {
  cursor: pointer;
  padding: 15px;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}

.autosuggest__results .autosuggest__results_title {
  color: gray;
  font-size: 11px;
  margin-left: 0;
  padding: 15px 13px 5px;
  border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results_item:active,
.autosuggest__results .autosuggest__results_item:hover,
.autosuggest__results .autosuggest__results_item:focus,
.autosuggest__results
  .autosuggest__results_item.autosuggest__results_item-highlighted {
  background-color: #ddd;
}

.header .container,
.page-content-wrapper > .container {
  max-width: 1400px !important;
  padding: 0 var(--gap);
  width: 100% !important;
}

.modal-content {
  background-color: #EBEBEB !important;
}