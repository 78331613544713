
dt {
  font-size: 1.1em;
  margin-bottom: 0.5em;
}
dd {
  margin-bottom: 1em;
}
dd.feed-url {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
