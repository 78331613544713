
.narratives-table {
  max-height: 500px;
}
.tooltip-narrative-preview .tooltip-inner{
  text-align: left !important;
  max-width: 450px !important;
  background: white;
  color: black;
  border: 1px solid #eaeaea;
}
.preview-title {
  text-align: center !important;
  font-size: 30px;
}
.download-feeds > h3 {
  line-height: 20px;
  flex-grow: 1;
}
.tooltip.tooltip-bottom .tooltip-inner::before {
  border-bottom-color: #eaeaea;
}
