
.modal-header {
  border: 0 !important;
}
.modal .close {
  margin: 0;
  padding: 0;
}
h3 {
  font-size: 15px;
}
#confirm-dialog {
  top: 25%;
}
