
.onboarding__background-section[data-v-a81eb108] {
  padding: 2rem 3rem;
}
.onboarding__background-inner[data-v-a81eb108] {
  height: 100%;
  width: 100%;
}
.onboarding__title[data-v-a81eb108] {
  font-size: 22px !important;
}
.onboarding__btn[data-v-a81eb108] {
  font-size: 20px;
  padding: 11px 20px;
}
.onboarding__btn-icon[data-v-a81eb108] {
  font-size: 22px;
  margin-right: 5px;
}
.onboarding__beta[data-v-a81eb108],
.onboarding__copy[data-v-a81eb108] {
  font-size: 14px;
}
.onboarding__btn-text[data-v-a81eb108] {
  font-size: 17px;
  font-weight: 800;
}
.onboarding-content[data-v-a81eb108] {
  background-color: #fedd4e;
  padding-top: 3rem;
}
@media (min-width: 768px) {
.onboarding-content[data-v-a81eb108] {
    padding-top: 13rem;
}
}
.container-fluid[data-v-a81eb108] {
  background-color: #157ef3;
}
body[data-v-a81eb108] {
  background-color: #157ef3;
}
.fh[data-v-a81eb108] {
  height: 100%;
  min-height: 100%;
}
.fw[data-v-a81eb108] {
  width: 100%;
  min-width: 100%;
}
