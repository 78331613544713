
.card-info p.bold {
  margin: 15px 0;
}
.api-info-pre {
  background: #f7f7f7;
  padding: 15px;
  margin-top: 10px;
  border-radius: 5px;
  max-width: 410px;
  overflow-x: auto;
  font-weight: 100;
}
