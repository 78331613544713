
.cover-feed__filters-row + .cover-feed__filters-row {
  margin-bottom: 0 !important;
}
.cover-feed__filters-row span {
  margin-right: .5rem;
}
.cover-feed__sort-by {
  cursor: pointer;
}
.suscribed-legend {
  color: #157ef3;
  font-size: 1.2em;
  text-align: right;
}
.icon-suscribed-legend {
  color: #157ef3;
}
.icon-suscribed {
  color: #157ef3;
  position: absolute;
  font-size: 1.9em;
  text-align: right;
  right: 50px;
  top: 6px;
}
#feed-search {
  border-color: #ccc;
}
