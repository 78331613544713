
.feeds-view {
  min-height: 60vh;
}
table {
  border: 1px solid #eaeaea;
}
table td.year,
td.month {
  text-align: center;
}
table td.posts {
  text-align: right;
}
.selected-plan {
  border: 2px solid #157ef3;
  background-color: #157ef3;
  color: white !important;
}
h4.selected-plan {
  color: white;
}
