
h1[data-v-1cd1d139] {
  font-size: 1.5em !important;
  line-height: 1.3em;
}
h2[data-v-1cd1d139] {
  font-size: 1.25em !important;
  line-height: 1.3em;
}
h3[data-v-1cd1d139] {
  font-size: 1em !important;
  line-height: 1.3em;
}
#metainfo[data-v-1cd1d139] {
  border-bottom: 1px solid #ddd;
  padding-bottom: 1em;
}
textarea[data-v-1cd1d139] {
  height: 100%;
}
#inputTitle[data-v-1cd1d139] {
  font-size: 1.6em;
}
#inputSubtitle[data-v-1cd1d139] {
  font-size: 1.3em;
}
#inputLeadin[data-v-1cd1d139] {
  font-size: 1.1em;
}
input[data-v-1cd1d139] {
  padding: 6px !important;
}
textarea[data-v-1cd1d139] {
  padding: 6px !important;
}
label[data-v-1cd1d139] {
  color: #157ef3 !important;
}
