
.feed-filters {
  background-color: #eee !important
}
.feed-filters__header {
  cursor: pointer;
  display: none;
}
.filter-list {
  border-bottom: 1px solid #ccc;
}
.filter-list label {
  cursor: pointer;
}
.filter-list h3 {
  font-size: 1.1em !important;
  font-weight: bold;
}
.filter-list ul {
  list-style-type: none;
  padding-left: 0px;
}
.filter-list ul li input {
  margin-right: 1em;
}
@media (max-width: 992px) {
.feed-filters {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    position: fixed;
    top: 66px;
    left: 0;
    transform: translateX(-280px);
    z-index: 99;
    width: 280px;
    bottom: 0;
    background: #000;
    transition: transform .5s ease;
    padding: 0 .6rem;
    overflow-y: scroll;
}
.feed-filters.open {
    transform: translateX(0);
}
.feed-filters__header {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
}
}
