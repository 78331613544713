
.feed-title {
  font-size: 2em;
  display: inline;
}
.preview {
  border: 2px solid #000;
  padding: 1em;
  padding-top: 0px;
  margin-bottom: 4em;
  background-color: #fff;
}
#newspaper-title h1 {
  text-align: center;
  font-size: 3em !important;
  font-family: 'Times New Roman', Times, serif !important;
}
.newspaper-section {
  padding-left: 1em;
  padding-right: 1em;
}
.fake {
  color: #ccc !important;
}
.newspaper-breadcrumb a {
  text-transform: capitalize;
  color: blue;
}
.newspaper-news h1 {
  font-size: 2.3em !important;
}
#newspaper-author {
  font-style: italic;
}
#newspaper-author a {
  text-decoration: underline;
}
#newspaper-date {
  font-size: 0.8em;
  margin-top: -1em;
}
#newspaper-icons {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
#picurl {
  margin-bottom: 2em;
  margin-top: 2em;
}
#newspaper-comments {
  margin-top: 2em;
  margin-bottom: 2em;
}
#newspaper-lateral-banner {
  background-color: #eee;
  color: white;
  font-size: 1.7em;
  padding: 1em;
  line-height: 1em;
  margin-top: 1em;
}
#newspaper-central-banner {
  background-color: #eee;
  color: white;
  font-size: 2em;
  text-align: center;
  margin-bottom: 1em;
  margin-top: 1em;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}
#newspaper-text p {
  font-size: 1em;
  line-height: 1.5em;
  margin-bottom: 1.5em;
}
#newspaper-best-news {
  margin-top: 5em;
}
#newspaper-best-news .row {
  margin-top: 1em;
}
#newspaper-best-news .number {
  font-size: 2.5em;
  margin-top: 0em;
}
#newspaper-best-news .title {
  font-size: 1em;
  line-height: 1.3em;
  margin-top: 0.6em;
}
#newspaper-best-news .image {
  background-color: #eee;
}
#newspaper-foot-news .image {
  background-color: #eee;
}
#browser {
  background-color: #000;
  height: 2.5em;
}
#url-direction {
  background-color: white;
  height: 1.5em;
  margin-top: 0.45em;
  width: 47em;
}
#browser-controls {
  height: 1.5em;
  margin-top: 0.45em;
  color: white;
}
