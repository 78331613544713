
.page_header[data-v-7d41ea0e] {
  background-color: black !important;
}
.header_bottom_line[data-v-7d41ea0e] {
  border-bottom: 0.5em solid #fedd4e !important;
}
.dashboard[data-v-7d41ea0e] {
  background-color: #eee !important;
}
