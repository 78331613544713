
h3 {
  font-size: 15px;
}
#progress-dialog {
  top: 25%;
}
#progress-dialog  .message-container {
  padding: 10px 15px;
  text-align: center;
}
#progress-dialog .modal-footer {
  padding: 15px !important;
}
