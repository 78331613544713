
.message-progress {
  padding: 30px 20px;
  border: 1px solid #eaeaea;
  height: 80px;
  display: flex;
  max-width: 275px;
  min-width: 250px;

  position: absolute;
  left: 40%;
  top: 35%;
}
.m-spinner {
  margin: 0 10px 0 0 !important;
  position: relative !important;
}
