
.feed-post[data-v-322923e3] {
  border: 1px solid #ccc;
  margin-bottom: 3em;
  padding: 1em;
  background-color: #fff;
}
.feed-post-time[data-v-322923e3] {
  border: 1px solid #ccc;
  padding: 0.3em 1em 0.3em 1em;
  display: inline-block;
  background-color: #eee;
}
h1[data-v-322923e3] {
  font-size: 1.5em !important;
  line-height: 1.3em;
}
h2[data-v-322923e3] {
  font-size: 1.25em !important;
  line-height: 1.3em;
}
h3[data-v-322923e3] {
  font-size: 1em !important;
  line-height: 1.3em;
}
#metainfo[data-v-322923e3] {
  border-bottom: 1px solid #ddd;
  padding-bottom: 1em;
}
.picurl[data-v-322923e3] {
  text-align: center;
}
