
.feed_disabled td {
  background-color: #efefef !important;
  color: grey;
}
.already_suscribed {
  border: 2px solid #157ef3 !important;
}
.tags-list {
  text-align: right;
}
.img-thumbnail {
  padding: 0;
  background-color: #fff;
  border-width: 0px !important;
  border-radius: 0 !important;

  width: 100% !important;
  height: 125px;
  object-fit: cover;
  display: block;
}
.card-body {
  padding: 0.5em;
}
.card-text {
  text-align: left !important;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.suscribed-legend {
  color: #157ef3;
  font-size: 1.2em;
  text-align: right;
}
.icon-suscribed-legend {
  color: #157ef3;
}
.icon-suscribed {
  color: #157ef3;
  position: absolute;
  font-size: 1.9em;
  text-align: right;
  right: 50px;
  top: 6px;
}
.icon-language {
  text-transform: uppercase;
  font-size: 0.8em;
  text-align: center;
  /*background-color: #CCC;*/
  padding: 4px;
  color: black;
  border: 0px solid black;
  /*border-radius: 100px;*/
}

/*
  .icon-language-de{
    background-color: #f7c700;
    color: black;
  }

  
  .icon-language-es{
    background-color: #b10000;
    color: white;
  }

  .icon-language-ar{
    background-color: #00742a;
    color: white;
  }

  .icon-language-en{
    background-color: #012169;
    color: white;
  }
  */
.full-item {
  border: 1px solid rgba(0, 0, 0, 0.001);
  background-color: #fff;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important
}
.full-item.already_suscribed{
  /*box-shadow: 0 1px 3px 0 #157ef3;*/
}
.full-item:hover {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
}
.extra-info {
  text-align: right;
  margin-bottom: 0em;
}
