
table.table[data-v-1693b056] {
  border: 1px solid #ccc;
}
table tr th[data-v-1693b056] {
  background-color: #fff;
}
.selected-plan[data-v-1693b056] {
  border: 2px solid #157ef3;
  background-color: #157ef3;
  color: white !important;
}
tr.totals td[data-v-1693b056] {
  font-weight: bold;
  text-align: right;
  font-size: 1.3em;
}
