
.filter-container {
  margin: 10px 0px;
}
.custom__tag {
  color: white;
  display: inline-block;
  padding: 3px 12px;
  background: #157EF3;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 10px;
  cursor: pointer;
}
.custom_remove {
  padding-left: 5px;
}
.multiselect__tags {
  border-radius: 0;
  min-height: 45px;
}
.multiselect__element {
  padding: 0;
}
.multiselect__single {
  line-height: 27px;
}
.multiselect__content-wrapper {
}
