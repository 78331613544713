
.forgot-password[data-v-25aa37db] {
  color: #000;
}
.forgot-password a[data-v-25aa37db] {
  color: #000;
  text-decoration: underline;
  font-weight: 800;
}
.forgot-password__inner[data-v-25aa37db] {
  height: 100%;
  margin: 0 auto;
  max-width: 460px;
  width: 90%;
}
.forgot-password__logo-wrap[data-v-25aa37db] {
  margin-bottom: 5rem;
}
.forgot-password__title[data-v-25aa37db] {
  font-size: 28px !important;
  font-weight: 800;
  margin-bottom: 1rem;
}
.forgot-password__copy[data-v-25aa37db] {
  font-size: 16px;
  margin-bottom: 2rem;
}
.forgot-password__error[data-v-25aa37db] {
  margin-bottom: 1rem;
}
.forgot-password__btn[data-v-25aa37db] {
  font-size: 16px;
  font-weight: 800;
}
.forgot-password__btn[data-v-25aa37db]:hover {
  background-color: #000;
  color: #fff;
}
.forgot-password__return[data-v-25aa37db] {
  font-size: 14px !important;
}
.forgot-password__fields[data-v-25aa37db] {
  margin-bottom: 1.8rem;
}
.recaptcha-terms[data-v-25aa37db]{
  font-size: .8em;
  margin-bottom: 2em;
}
@media (min-width: 992px) {
.forgot-password__btn-col[data-v-25aa37db] {
    min-width: 220px !important;
    max-width: 220px !important;
}
}
@media (min-width: 1360px) {
.forgot-password__inner[data-v-25aa37db] {
      width: 75%;
}
}

