
.reset-password__title[data-v-c3bf20b8] {
  font-size: 28px !important;
  font-weight: 800;
  margin-bottom: 1rem;
}
.reset-password__copy[data-v-c3bf20b8] {
  font-size: 16px;
  margin-bottom: 2rem;
}
.reset-password__btn[data-v-c3bf20b8] {
  font-size: 16px;
  font-weight: 800;
}
.reset-password__btn[data-v-c3bf20b8]:hover {
  background-color: #000;
  color: #fff;
}
