
.header-xl-height[data-v-f90954f8] {
  height: 5em !important;
}
.header-inner[data-v-f90954f8] {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
#log-info a[data-v-f90954f8] {
  color: white;
}
.pull-right a[data-v-f90954f8]{
  margin-left: 1em;
}
.sign_up_button[data-v-f90954f8]{
  background-color: #007bff;
  color: white !important;
  padding: 1.2em 2em;
  border-radius: 5px;
  font-weight: bolder;
}
.sign_up_button i[data-v-f90954f8]{
  padding-right: .5em;
}

