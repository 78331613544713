
.tabs_parent_bg[data-v-7dcfc4c3] {
  background-color: #157ef3;
  color: white;
}
.card-header[data-v-7dcfc4c3] {
  background-color: #157ef3;
  padding: 9px !important;
  font-size: 1.1em !important;
  min-height: 0px !important;
  font-weight: bold;
  color: white !important;
}
@media (min-width: 992px) {
.client-status-root[data-v-7dcfc4c3] {
    margin-top: 17.6rem;
}
.manage-plans .client-status-root[data-v-7dcfc4c3] {
    margin-top: 12.6rem;
}
}
.card-header[data-v-7dcfc4c3] {
  font-size: 1.2em;
  padding: 10px;
}
#manage_plan_link[data-v-7dcfc4c3] {
  font-weight: bold;
  margin-top: 1em !important;
}
#manage_plan_link[data-v-7dcfc4c3]:hover {
  text-decoration: underline;
}
h1.limit_reached[data-v-7dcfc4c3] {
  font-weight: bold;
  color: red !important;
  text-align: center;
}
.suscription_limit[data-v-7dcfc4c3] {
  border-color: 1px solid red;
  background-color: #ffeaee;
}
.suscription_limit .card-header[data-v-7dcfc4c3] {
  background-color: #b10000;
  color: white;
  border: 1px solid #ff0000;
}
.suscription_limit .card-body[data-v-7dcfc4c3] {
  border: 1px solid #ff0000;
  border-top: 0px;
}
.card[data-v-7dcfc4c3] {
  /*border-color: #ccc;*/
  border: 0px;
}
.card-updates h2[data-v-7dcfc4c3] {
  color: #157ef3;
  font-weight: bold;
  font-size: 1em !important;
  line-height: 0.7em;
}
.card-header[data-v-7dcfc4c3] {
  border: 1px solid #157ef3;
}
.card-body[data-v-7dcfc4c3] {
  border: 1px solid #157ef3;
}
.card-updates h1[data-v-7dcfc4c3] {
  font-weight: bold;
  font-size: 1.2em !important;
  line-height: 1.1em;
}
.card-updates p[data-v-7dcfc4c3] {
  font-size: 1em !important;
  line-height: 1.1em;
}
.posts-count[data-v-7dcfc4c3] {
  font-size: 1.2em;
  font-weight: bold;
}
