
.payment-methods-block {
  min-height: 135px;
}
.empty-methods {
  text-align: center;
}
.ta-r {
  text-align: right;
}
