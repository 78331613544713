
#feed-back-header {
  background-color: #157ef3;
  color: white;
}
#feed-back-header h1.feed-title {
  color: white !important;
}
#tabs {
  margin-left: 0px;
}
#tabs div {
  margin-right: 3px;
}
#tabs h2 {
  color: white !important;
}
#tabs .tab-active {
  background-color: #000;
}
#tabs .tab {
  background-color: #fedd4e;
}
#tabs .tab h2 {
  color: black !important;
}
#subscribe {
  margin-top: 2em;
  margin-bottom: 4em !important;
}
