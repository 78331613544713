
.signup-popup.modal .modal-content[data-v-3fa6d57a] {
  background-color: #EBEBEB !important;
}
.signup-popup__inner[data-v-3fa6d57a] {
  padding: 35px 20px !important
}
.signup-popup__logo[data-v-3fa6d57a] {
  margin-bottom: 2rem;
}
.signup-popup__title[data-v-3fa6d57a] {
  font-size: 20px !important;
  font-weight: 800;
}
.signup-popup__copy[data-v-3fa6d57a] {
  font-size: 16px !important;
}
.signup-popup__btn[data-v-3fa6d57a] {
  font-size: 16px !important;
  font-weight: 800 !important;
}
.recaptcha-terms[data-v-3fa6d57a]{
  font-size: .8em;
  color: #999;
  margin-bottom: 1em;
}
.recaptcha-terms a[data-v-3fa6d57a]{
  color: rgb(110, 110, 223);
}
.signup-popup .modal-dialog[data-v-3fa6d57a]{
  max-width: 600px;
}
.signup-popup .form-group-default[data-v-3fa6d57a] {
  position: relative;
}
.signup-popup .form-group-default.focused[data-v-3fa6d57a] {
  background-color: #fff!important;
}
.signup-popup .form-group-default.focused label[data-v-3fa6d57a] {
  opacity: 1 !important;
}
.signup-popup .reset-password__reveal[data-v-3fa6d57a] {
  cursor: pointer;
  font-size: 15px;
  margin-right: 5px;
  position: absolute;
  right: 5px;
  top: 24px;
}
.signup__submit-error[data-v-3fa6d57a] {
  margin-top: 1rem;
}
.signup-popup.modal .close[data-v-3fa6d57a] {
  margin: 0;
  padding: 0;
  top: 6px;
  right: 10px;
}
.signup-popup__btn:disabled .signup-popup__btn-text[data-v-3fa6d57a] {
  margin-left: -21px;
}

