
.page_header[data-v-9e3561e4] {
  background-color: black !important;
  border-bottom: 0.5em solid #fedd4e !important;
}
.header_bottom_line[data-v-9e3561e4] {
  border-bottom: 0.5em solid #fedd4e !important;
}
.dashboard[data-v-9e3561e4] {
  background-color: #eee !important;
}
