
.reset-password[data-v-0dbb289e] {
  color: #000;
}
.reset-password a[data-v-0dbb289e] {
  color: #000;
  text-decoration: underline;
  font-weight: 800;
}
.reset-password__inner[data-v-0dbb289e] {
  height: 100%;
  margin: 0 auto;
  max-width: 460px;
  width: 90%;
}
.reset-password__logo-wrap[data-v-0dbb289e] {
  margin-bottom: 5rem;
}
.reset-password__title[data-v-0dbb289e] {
  font-size: 28px !important;
  font-weight: 800;
  margin-bottom: 1rem;
}
.reset-password__copy[data-v-0dbb289e] {
  font-size: 16px;
  margin-bottom: 2rem;
}
.reset-password__form-error[data-v-0dbb289e] {
  margin-bottom: .5rem;
}
.reset-password__btn[data-v-0dbb289e] {
  font-size: 16px;
  font-weight: 800;
}
.reset-password__btn[data-v-0dbb289e]:hover {
  background-color: #000;
  color: #fff;
}
.reset-password__fields[data-v-0dbb289e]{
  margin-bottom: 1.8rem;
}
.reset-password .controls[data-v-0dbb289e] {
  position: relative;
}
.reset-password__reveal[data-v-0dbb289e] {
  cursor: pointer;
  font-size: 15px;
  margin-right: 5px;
  position: absolute;
  right: 0;
  top: 0;
}
@media (min-width: 1360px) {
.reset-password__inner[data-v-0dbb289e] {
      width: 75%;
}
}
