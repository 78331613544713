
.feed-post {
  border: 1px solid #ccc;
  margin-bottom: 3em;
  padding: 1em;
  background-color: #fff;
}
.feed-post-warning {
  border-color: red !important;
}
.feed-post-time {
  border: 1px solid #ccc;
  padding: 0.3em 1em 0.3em 1em;
  display: inline-block;
  background-color: #eee;
}
h1 {
  font-size: 1.5em !important;
  line-height: 1.3em;
}
h2 {
  font-size: 1.25em !important;
  line-height: 1.3em;
}
h3 {
  font-size: 1em !important;
  line-height: 1.3em;
}
#metainfo {
  border-bottom: 1px solid #ddd;
  padding-bottom: 1em;
}
.picurl {
  text-align: center;
}
.btn-validation {
  margin-left: 1em;
  margin-right: 0em;
}
.tab-warning {
  background-color: red !important;
}
