
.cc-brand {
  width: 125px;
}
.cc-item {
  cursor: pointer;
  transition: .1s ease;
}
.cc-item:hover {
  background: #fafafa;
}
.active-card {
  border: 2px solid #fedd4e;
}
