
.feed-title {
  font-size: 2em;
  display: inline;
}
.examples-title {
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  /*border-bottom: 1px solid #ddd;*/
}
#manual_validation_buttons {
  font-size: 10px;
  float: right;
  margin-top: 1em;
}
#manual_validation_buttons button {
  font-size: 1em;
  padding: 0em 1em 0em 1em;
}
#manual_validation_buttons p {
  font-size: 1.5em;
  margin-bottom: 0px;
}
h1 {
  color: black !important;
}
a.btn-success {
  color: #fff !important;
}
