
.feeds-view {
  min-height: 60vh;
}
.feeds-view i {
  font-size: 18px !important;
}
table {
  border: 1px solid #eaeaea;
}
