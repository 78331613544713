
#footer {
  background-color: black;
  font-size: 0.9em;
}
#footer a {
  color: white !important;
}
#contact a {
  color: white;
}
#footer h3 {
  color: white;
  font-size: 1.5em !important;
}
#footer-social-links a {
  color: white !important;
  font-size: 1.5em;
  margin-left: 0.75em;
}
#footer-contact {
  line-height: 1em;
  line-height: 2.5em;
  font-size: 0.7em;
}
#footer-contact h4 {
  color: white;
  font-size: 1.8em;
  font-weight: bold;
}
#footer-links a {
  color: white;
  font-size: 1.3em;
  margin-bottom: 1em;
}
#footer-links li {
  margin-bottom: 1em;
}
#footer-links ul {
  list-style-type: none;
  margin-right: 0px;
}
.footer .menu {
  padding-left: 0 !important;
}
